import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Home from './home';
import {
    fetchProductList,
    setProductAccess,
    fetchAccountAccess,
    setAccountAccess,
    createAccountAccess,
    fetchUserCache,
    expireUserCache,
} from '../../actions/admin-actions';
import { fetchLabItems, fetchProducts, findLabsByProduct, clearLabDetails } from '../../actions/lab-actions';
import { fetchEventLabs, setEventId } from '../../actions/event-actions';
import { setStudentId, fetchStudentLabs, findUserLabs, findFilteredStudentLabs } from '../../actions/student-actions';

const mapStateToProps = (reducers) => {
    return reducers;
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchProductList,
        setProductAccess,
        fetchAccountAccess,
        setAccountAccess,
        createAccountAccess,
        clearLabDetails,
        fetchLabItems,
        fetchProducts,
        setEventId,
        fetchEventLabs,
        setStudentId,
        fetchStudentLabs,
        findLabsByProduct,
        findUserLabs,
        findFilteredStudentLabs,
        fetchUserCache,
        expireUserCache,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
