/**
 * Extends the navbar component of react-bootstrap
 * https://react-bootstrap.github.io/components/navbar/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { NAVBAR_ITEMS } from '../../constants';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavItem from 'react-bootstrap/NavItem';

export const NavBar = ({ handleNavClick }) => (
    <Navbar>
        {/* <Navbar.Header> */}
        <Navbar.Brand>
            <Link to={ NAVBAR_ITEMS[0].path }>My Labs Admin</Link>
        </Navbar.Brand>
        {/* </Navbar.Header> */}
        <Nav>
            { NAVBAR_ITEMS.map(({ name, path }) => (
                <NavItem data-path={ path } key={ path } href="#" onClick={ handleNavClick }>
                    { name }
                </NavItem>
            ))}
        </Nav>
    </Navbar>
);

NavBar.propTypes = {
    handleNavClick: PropTypes.func,
};
