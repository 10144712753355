/**
 * Extends the navbar component of react-bootstrap
 * https://react-bootstrap.github.io/components/navbar/
 */

import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import LabTable from '../lab-table';
import { capitalize } from '../../utils/capitalize';
export const FilteredTable = ({ rows, productLabs, appReducer, findLabs, dateField, tableContext }) => {
    let key = -1;

    const cols = [{
        title: 'Course Name',
        field: 'course_name',
    }, {
        title: 'Event Type',
        field: 'event_type',
    }, {
        title: 'Product Id',
        field: 'product_id',
    }, {
        title: `${capitalize(dateField)} Start`,
        field: `${dateField}_start`,
    }, {
        title: `${capitalize(dateField)} End`,
        field: `${dateField}_end`,
    }];

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    { R.map((col) => (
                        <th key={ col.field }>{ col.title }</th>
                    ), cols)}
                    <th>Action</th>
                </tr>
            </thead>
            { R.map((row) => {
                key++;
                return (
                    <tbody key={ key }>
                        <tr>
                            { R.map((col) => (
                                <td key={ col.field }>{ row[col.field] }</td>
                            ), cols)}
                            <td>
                                <Button
                                    variant="link"
                                    onClick={ findLabs }
                                    data-product={ row.product_id }
                                    data-type={ row.event_type }
                                    data-start={ row.event_start }
                                >Fetch Labs</Button>
                            </td>
                        </tr>
                        { productLabs[row.product_id] && 
                        productLabs[row.product_id].length > 0 &&
                        productLabs[row.product_id][0].event_type === row.event_type && (
                            <tr>
                                <td colSpan="99">
                                    <LabTable
                                        appAccess={ appReducer.appAccess }
                                        labs={ productLabs[row.product_id] }
                                        tableContext={ tableContext }
                                    />
                                </td>
                            </tr>
                        )}
                    </tbody>
                );
            }, rows) }
        </Table>
    );
};

FilteredTable.propTypes = {
    appReducer: PropTypes.object,
    productLabs: PropTypes.object,
    findUserLabs: PropTypes.func,
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            product_id: PropTypes.number,
            course_name: PropTypes.string,
            event_type: PropTypes.string,
            event_start: PropTypes.string,
            event_end: PropTypes.string,
        })
    ),
    filteredUserLabs: PropTypes.array,
    findLabs: PropTypes.func,
    dateField: PropTypes.string,
    tableContext: PropTypes.string,
};
