import './lab-view.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { PATH } from '../../constants';
import {
    FIELD_LABELS,
    LAB_FIELDS,
} from './lab-constants';
import * as R from 'ramda';

export const LabView = ({ appReducer, match, labReducer }) => (
    <div className="lab-view">
        <div className="controls">
            <h2>{ R.pathOr('Loading...', ['currentLab', 'id'], labReducer)}</h2>
            { appReducer.appAccess.write ? (
                <div>
                    { labReducer.currentLab.type === 'VPN' && (
                        <Link to={ PATH.labAction(match.params.id, PATH.lab_actions.CERTS) }>Upload Certs...</Link>
                    )}
                    <Link to={ PATH.labAction(match.params.id, PATH.lab_actions.EDIT) }>Edit...</Link>
                </div>
            ) : null }
        </div>
        <Table striped>
            <tbody>
                { R.map((key) => (
                    <tr key={ key }>
                        <th>{ FIELD_LABELS[key] }</th>
                        <td>{ R.propOr('---', key, labReducer.currentLab) }</td>
                    </tr>
                ), R.reject(R.equals('blueprint_file'), LAB_FIELDS)) }
            </tbody>
        </Table>
    </div>
);

LabView.propTypes = {
    appReducer: PropTypes.object,
    labReducer: PropTypes.object,
    match: PropTypes.object,
};

export default LabView;
