import '../tabs.scss';
import React from 'react';
import * as R from 'ramda';
import Products from './admin-tab/products';
import UserAccess from './admin-tab/user-access';
import UserCache from './admin-tab/user-cache';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Well from '../../../components/well';

class AdminTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            key: 'reports',
        };

        this.onSelect = R.bind(this.onSelect, this);
    }

    onSelect(key) {
        this.setState({ key });
    }

    render() {
        return (
            <Well className="admin-tab">
                <Tabs
                    activeKey={ this.state.key }
                    onSelect={ this.onSelect }
                    id="controlled-admin-tab">
                    {/* <Tab eventKey="reports" title="Reports">
                        <Reports />
                    </Tab> */}
                    <Tab eventKey="cache" title="User Cache">
                        <UserCache { ...this.props } />
                    </Tab>
                    <Tab eventKey="access" title="User Access">
                        <UserAccess { ...this.props } />
                    </Tab>
                    <Tab eventKey="products" title="Enable Products">
                        <Products { ...this.props } />
                    </Tab>
                </Tabs>
            </Well>
        );
    }
}

export default AdminTab;
