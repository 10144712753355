import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';

export const Well = (props) => (
    <Card>
        <Card.Header className="well" { ...props } />
    </Card>
);

Well.propTypes = {
    type: PropTypes.string,
};
