import ACTION_TYPES from '../actions/action-types';
import * as R from 'ramda';

const INITIAL_ADMIN_STATE = {
    products: null,
    accountAccess: [],
    userCache: [],
};

export default (state = INITIAL_ADMIN_STATE, { type, payload }) => {
    switch (type) {
        case ACTION_TYPES.GET_PRODUCT_LIST:
            return R.assoc('products', payload, state);
        case ACTION_TYPES.GET_USER_CACHE:
            return R.assoc('userCache', payload, state);
        case ACTION_TYPES.SET_PRODUCT_ACCESS:
            return R.assoc('products', R.map((prod) =>
                prod.id === payload.id ? R.assoc('has_remote_lab', payload.has_remote_lab, prod) : prod,
            state.products), state);
        case ACTION_TYPES.GET_ACCOUNT_ACCESS:
            return R.assoc('accountAccess', payload, state);
        case ACTION_TYPES.POST_ACCOUNT_ACCESS:
            return R.assoc('accountAccess', R.map((account) =>
                account.id === payload.id ? payload : account,
            state.accountAccess), state);
        case ACTION_TYPES.PUT_ACCOUNT_ACCESS:
            return R.assoc('accountAccess', R.append(payload, state.accountAccess), state);
        default:
            return state;
    }
};
