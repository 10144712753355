import './lab-instructions.scss';

import React from 'react';
import PropTypes from 'prop-types';
import LabDisplay from '../../submodules/shared/components/lab-display';
import Loading from '../../submodules/shared/components/loading';
import * as R from 'ramda';

class LabInstructions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            labResults: [],
        };
    }

    componentDidUpdate({ labReducer }) {
        const { currentLab } = this.props.labReducer;

        if (currentLab.instructions && currentLab.name && labReducer.currentLab !== currentLab) {
            this.setState({
                labResults: [currentLab],
            });
        }
    }

    render() {
        return (
            <div>
                { R.isEmpty(this.state.labResults) ? (
                    <Loading
                        message="Fetching lab information..."/>
                ) : (
                    <div className="lab-instructions">
                        <LabDisplay { ...this.state } />
                    </div>
                )}
            </div>
        );
    }
}

LabInstructions.propTypes = {
    labReducer: PropTypes.object,
};

export default LabInstructions;
