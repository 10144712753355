import * as R from 'ramda';

const pushValueToObjKey = (key, val, obj) =>
    R.assoc(key, R.append(val, R.propOr([], key, obj)), obj);

const groupLabInstructionsByType = (lab) => {
    let labObj = {/*
        [instruction.field_instruction_type]: [
            {instruction.field_instruction_type},
            {instruction.field_instruction_type},
        ],
     */};

    R.forEach((instruction) => {
        labObj = pushValueToObjKey([instruction.field_instruction_type], instruction, labObj);
    }, lab.instructions);

    return R.assoc('instructions', labObj, lab);
};

const groupByCourse = (labs) => {
    let groupedByCourse = {/*
        [product_id]: [
            {lab},
            {lab},
        ],
    */};

    R.forEach((lab) => {
        groupedByCourse = pushValueToObjKey(
            R.propOr('000', 'product_id', lab), //labs do not contain product_id in admin
            lab,
            groupedByCourse
        );
    }, labs);

    return groupedByCourse;
};

export const remapCourses = (courses) =>
    groupByCourse(R.map((lab) => groupLabInstructionsByType(lab), (courses)));
