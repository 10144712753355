import '../tabs.scss';
import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { SORT_DIRECTIONS } from '../../../constants';
import Button from 'react-bootstrap/Button';
import Well from '../../../components/well';
import SingleSelect from '../../../components/single-select';
import LabTable from '../../../components/lab-table';

const { ASC, DESC } = SORT_DIRECTIONS;

class LabTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orderBy: 'id',
            dir: ASC,
            where: {},
            filteredProdResults: [],
        };

        if (R.isEmpty(props.labReducer.productResults)) {
            props.fetchProducts();
        }

        if (R.isEmpty(props.labReducer.labResults)) {
            props.fetchLabItems(this.state);
        }

        this.changeOrderBy = R.bind(this.changeOrderBy, this);
        this.handleFilter = R.bind(this.handleFilter, this);
    }

    componentDidMount() {
        // Reload the table if the user came from a lab page. Without this if the user hits the back button
        // on a lab page, the product filter will not populate correctly.
        if (!R.isEmpty(this.props.labReducer.currentLab)) {
            window.location.reload();
        }
    }

    componentDidUpdate() {
        const { labResults, productResults } = this.props.labReducer;

        if (!R.isEmpty(labResults) && !R.isEmpty(productResults) && R.isEmpty(this.state.filteredProdResults)) {
            const filteredProdResults = [];

            //filter productResults to just use products used in labResults, rename id -> value, and name -> label
            R.forEach((prod) => {
                if (R.find(R.propEq('product_id', prod.id), labResults)) {
                    filteredProdResults.push({
                        label: prod.name,
                        value: prod.id,
                    });
                }
            }, productResults);
            this.setState({
                filteredProdResults,
            });
        }
    }

    changeOrderBy({ target }) {
        const orderBy = target.innerText.toLowerCase();

        const filter = {
            orderBy,
            dir: orderBy === this.state.orderBy && this.state.dir === ASC ? DESC : ASC,
        };

        this.setState(filter);
        this.props.fetchLabItems(filter);
    }

    handleFilter({ target }) {
        const where = {
            where: {
                product_id: target.value,
            },
        };

        this.setState(where);
        this.props.fetchLabItems(R.mergeRight(this.state, where));
    }

    render() {
        return (
            <div className="lab-tab">
                <Well>
                    <SingleSelect
                        id="product_id"
                        label="Filter By Product"
                        selected={ R.path(['where', 'product_id'], this.state) }
                        options={ this.state.filteredProdResults }
                        onChange={ this.handleFilter }
                    />
                    { this.props.appReducer.appAccess.write ? (
                        <Button variant="success" onClick={ this.props.clearLabDetails }> Add Lab </Button>
                    ) : null }
                </Well>
                <LabTable
                    appAccess={ this.props.appReducer.appAccess }
                    labs={ this.props.labReducer.labResults }
                    changeOrderBy={ this.changeOrderBy }
                />
            </div>
        );
    }
}

LabTab.propTypes = {
    appReducer: PropTypes.object,
    labReducer: PropTypes.object,
    fetchLabItems: PropTypes.func,
    fetchProducts: PropTypes.func,
    clearLabDetails: PropTypes.func,
};

export default LabTab;
