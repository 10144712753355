//This is included in the app.jsx
//import 'react-select/dist/react-select.scss';

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import * as R from 'ramda';
import Select from 'react-select';

import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';

class SingleSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.selected,
        };

        this.onChange = R.bind(this.onChange, this);
    }

    componentDidUpdate({ selected }) {
        if (!this.state.value && selected) {
            this.setState({
                value: selected,
            });
        }
    }

    onChange(event) {
        const value = R.propOr(null, 'value', event);

        this.setState({
            value,
        });

        this.props.onChange({
            target: {
                id: this.props.id,
                value,
            },
        });
    }
    render() {
        return (
            <FormGroup
                className="single-select"
                id={ this.props.id }
            >
                <FormLabel>
                    { this.props.label }
                </FormLabel>
                { this.state.value }
                <Select
                    value={ R.filter((x) => x.value === this.state.value, this.props.options) }
                    onChange={ this.onChange }
                    options={ this.props.options }
                />
            </FormGroup>
        );
    }
}

SingleSelect.propTypes = {
    id: PropTypes.string,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
};

export default SingleSelect;
