export const FIELD_LABELS = {
    id: 'Id',
    name: 'Name',
    status: 'Status',
    product_id: 'Product id',
    type: 'Type',
    section: 'Section',
    on_demand_training_id_start: 'OnDemand training Id (Start)',
    on_demand_training_id_end: 'OnDemand training Id (End)',
    live_event_start: 'Live event (Start)',
    live_event_end: 'Live event (End)',
    courseware_start: 'Courseware (Start)',
    courseware_end: 'Courseware (End)',
    include_ondemand: 'OnDemand',
    include_vlive: 'vLive',
    include_simulcast: 'Simulcast',
    include_mentor: 'Mentor',
    include_community: 'Community',
    include_conference: 'Conference/Summit',
    include_mpo: 'MPO',
    parent: 'Parent',
    sim_lab: 'SIM Lab',
    default_fqdn: 'Default FQDN',
    ovpn_linux: 'OVPN Linux',
    ovpn_linux_resolved: 'OVPN Linux update-systemd-resolved up/down',
    ovpn_windows: 'OVPN Windows',
    ip_address: 'External IP Address',
    gateway_ip: 'Gateway IP',
    gateway_name: 'Gateway Name',
    tls_cipher_exception_linux: 'TLS Cipher - Linux',
    tls_cipher_exception_windows: 'TLS Cipher - Windows',
    game_name: 'Game Name',
    host_name: 'Host Name',
    course_start_date: 'Course Start Date',
    blueprint_file: 'Blueprint File',
    auto_start: 'Auto Start Game',
    auth_key: 'Authentication Key',
    s3_bucket: 'S3 Bucket',
    pfSense_format: 'Generate pfSense Format',
    pfSense_tls: 'With TLS Auth Support',
    shared_environment: 'Shared Environment',
    lab_series: 'Lab Series',
    lab_2_date: 'Lab 2 Start Date',
};

export const FIELD_PLACEHOLDERS = {
    id: 'Lab Id',
    name: 'Lab Name',
    on_demand_training_id_start: '(0-999999999)',
    on_demand_training_id_end: '(0-999999999)',
    courseware_start: 'Courseware Start',
    courseware_end: 'Courseware End',
    parent: 'Parent Lab Id',
    sim_lab: 'SIM Lab',
    default_fqdn: 'Default FQDN',
    ovpn_linux: 'OVPN Linux',
    ovpn_windows: 'OVPN Windows',
    ip_address: 'External IP Address',
    gateway_ip: 'Gateway IP',
    gateway_name: 'Gateway Name',
    game_name: 'Game Name',
    host_name: 'Host Name',
    course_start_date: 'Course Start Date',
    blueprint_file: 'Blueprint File',
    auto_start: 'Auto Start Game',
    auth_key: 'Authentication Key',
    s3_bucket: 'S3 Bucket',
    lab_series: 'Lab Series',
    lab_2_date: 'Lab 2 Start Date',
};

//Used to set state in lab-edit;
//Used to get values in lab-view
export const LAB_FIELDS = [
    'id',
    'name',
    'status',
    'product_id',
    'type',
    'section',
    'on_demand_training_id_start',
    'on_demand_training_id_end',
    'live_event_start',
    'live_event_end',
    'courseware_start',
    'courseware_end',
    'include_ondemand',
    'include_vlive',
    'include_simulcast',
    'include_mentor',
    'include_community',
    'include_conference',
    'include_mpo',
    'parent',
    'sim_lab',
    'default_fqdn',
    'ovpn_linux',
    'ovpn_windows',
    'ovpn_linux_resolved',
    'ip_address',
    'gateway_ip',
    'gateway_name',
    'tls_cipher_exception_linux',
    'tls_cipher_exception_windows',
    'game_name',
    'host_name',
    'course_start_date',
    'blueprint_file',
    'auto_start',
    'auth_key',
    's3_bucket',
    'pfSense_format',
    'pfSense_tls',
    'shared_environment',
    'lab_series',
    'lab_2_date',
];
