import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Lab from './lab';
import {
    upsertLab,
    fetchLabDetails,
    clearLabDetails,
    fetchLabInstructions,
    uploadLabKeys,
    fetchProducts,
} from '../../actions/lab-actions';

const mapStateToProps = ({ appReducer, labReducer, eventReducer }) =>
    ({
        appReducer,
        labReducer,
        eventReducer,
    });

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        upsertLab,
        fetchLabDetails,
        clearLabDetails,
        fetchLabInstructions,
        uploadLabKeys,
        fetchProducts,
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Lab);
