import ACTION_TYPES from '../actions/action-types';

const INITIAL_ACCOUNT_STATE = {
    sansAccountID: null,
    sansEmail: null,
    name: null,
    //authExpiration: "2019-02-25T23:23:09.000Z"
    //authGranted: "2019-02-25T20:52:44.296Z"
    //principalId: "smcclaine@sans.org"
};

export default (state = INITIAL_ACCOUNT_STATE, { type, payload }) => {
    switch (type) {
        case ACTION_TYPES.SET_ACCOUNT_DETAILS:
            return payload;
        default:
            return state;
    }
};
