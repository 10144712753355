import ACTION_TYPES from './action-types';
import * as R from 'ramda';
import {
    getUserCache,
    getProductList,
    postProductAccess,
    getAccountAccess,
    postAccountAccess,
    putAccountAccess,
    postUserCache,
} from '../utils/api';

export const fetchProductList = () =>
    (dispatch) => {
        getProductList().then(({ data }) => {
            dispatch({
                type: ACTION_TYPES.GET_PRODUCT_LIST,
                payload: data,
            });
        }, () => {
            //onError set data to empty array
            //Error is most likely because they don't have access to the lambda endpoint
            dispatch({
                type: ACTION_TYPES.GET_PRODUCT_LIST,
                payload: [],
            });
        });
    };

export const setProductAccess = (payload) =>
    (dispatch) => {
        postProductAccess(payload).then(({ data }) => {
            if (data.code === 404) {
                dispatch({
                    type: ACTION_TYPES.SHOW_NOTIFICATION_MESSAGE,
                    payload: { type: 'danger', message: 'There was an error processing your request' },
                });
            } else {
                dispatch({
                    type: ACTION_TYPES.SET_PRODUCT_ACCESS,
                    payload: data,
                });
            }
        }, () => {
            dispatch({
                type: ACTION_TYPES.SHOW_NOTIFICATION_MESSAGE,
                payload: { type: 'danger', message: 'There was an error processing your request' },
            });
        });
    };
export const fetchAccountAccess = () =>
    (dispatch) => {
        getAccountAccess().then(({ data }) => {
            dispatch({
                type: ACTION_TYPES.GET_ACCOUNT_ACCESS,
                payload: data,
            });
        }, () => {
            //onError set data to empty array
            //Error is most likely because they don't have access to the lambda endpoint
            dispatch({
                type: ACTION_TYPES.GET_ACCOUNT_ACCESS,
                payload: [],
            });
        });
    };
export const setAccountAccess = (payload) =>
    (dispatch) => {
        postAccountAccess(payload).then(({ data }) => {
            if (data.code === 404) {
                dispatch({
                    type: ACTION_TYPES.SHOW_NOTIFICATION_MESSAGE,
                    payload: { type: 'danger', message: 'There was an error processing your request' },
                });
            } else {
                dispatch({
                    type: ACTION_TYPES.POST_ACCOUNT_ACCESS,
                    payload,
                });
            }
        }, () => {
            dispatch({
                type: ACTION_TYPES.SHOW_NOTIFICATION_MESSAGE,
                payload: { type: 'danger', message: 'There was an error processing your request' },
            });
        });
    };

export const createAccountAccess = (payload) =>
    (dispatch) => {
        putAccountAccess(payload).then(({ data }) => {
            if (data.code === 404) {
                dispatch({
                    type: ACTION_TYPES.SHOW_NOTIFICATION_MESSAGE,
                    payload: { type: 'danger', message: 'There was an error processing your request' },
                });
            } else {
                dispatch({
                    type: ACTION_TYPES.PUT_ACCOUNT_ACCESS,
                    payload: R.assoc('id', data.insertId, payload),
                });
            }
        }, () => {
            dispatch({
                type: ACTION_TYPES.SHOW_NOTIFICATION_MESSAGE,
                payload: { type: 'danger', message: 'There was an error processing your request' },
            });
        });
    };

const fUserCache = (dispatch) =>
    getUserCache().then(({ data }) => {
        dispatch({
            type: ACTION_TYPES.GET_USER_CACHE,
            payload: data,
        });
    }, () => {
        dispatch({
            type: ACTION_TYPES.SHOW_NOTIFICATION_MESSAGE,
            payload: { type: 'danger', message: 'There was an error processing your request' },
        });
    });

export const fetchUserCache = () =>
    (dispatch) => fUserCache(dispatch);

export const expireUserCache = (payload) =>
    (dispatch) =>
        postUserCache(payload).then(() => {
            fUserCache(dispatch);
        }, () => {
            dispatch({
                type: ACTION_TYPES.SHOW_NOTIFICATION_MESSAGE,
                payload: { type: 'danger', message: 'There was an error processing your request' },
            });
        });
