import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import AppContainer from './views/app';
import { Provider } from 'react-redux';

import thunk from 'redux-thunk';

import { applyMiddleware, compose, createStore } from 'redux';

import reducers from './reducers/root';

const middleware = applyMiddleware(thunk);

const enhancer =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION__ ?
        compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__()) :
        middleware;

const store = createStore(reducers, enhancer);

ReactDOM.render(
    <Provider store={ store }>
        <AppContainer />
    </Provider>,
    document.getElementById('root'),
);
