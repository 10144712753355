import 'bootstrap/dist/css/bootstrap.min.css';
import './app.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Router, Redirect, Route, Switch } from 'react-router-dom';

import { PATH } from '../../constants';
import history from '../../submodules/shared/utils/history';

import NavBar from '../../components/nav-bar';
import HomeContainer from '../../views/home';
import LabContainer from '../../views/lab';

import Notifications from '../../components/notifications';

class App extends Component {
    constructor(props) {
        super(props);

        props.fetchAppAccess({ accountId: 1 }); ///@TODO - this can't be hard coded!!!
    }

    handleNavClick({ target }) {
        history.push(target.dataset.path);
    }

    render() {
        const {
            messages,
            hideNotificationItem,
        } = this.props;

        return (
            <div className="App">
                <Router history={ history } >
                    <div className="router">
                        <NavBar handleNavClick={ this.handleNavClick }/>
                        <Notifications
                            messages={ messages }
                            hideNotificationItem={ hideNotificationItem }
                        />
                        <Switch>
                            <Route path={ PATH.home } component={ HomeContainer }/>
                            <Route exact path={ PATH.lab } component={ LabContainer }/>
                            <Route path={ PATH.labAction() } component={ LabContainer }/>
                            <Redirect from={ PATH.base } to={ PATH.home }/>
                        </Switch>
                    </div>
                </Router>
            </div>
        );
    }
}

App.propTypes = {
    appReducer: PropTypes.object,
    messages: PropTypes.array,
    clearNotifications: PropTypes.func,
    hideNotificationItem: PropTypes.func,
    fetchAppAccess: PropTypes.func,
};

export default App;
