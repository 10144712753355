import ACTION_TYPES from '../actions/action-types';
import * as R from 'ramda';

const INITIAL_APP_STATE = {
    messages: [],
    appAccess: {
        admin: 0,
        write: 0,
        read: 0,
    },
};

export default (state = INITIAL_APP_STATE, { type, payload }) => {
    switch (type) {
        case ACTION_TYPES.SET_APP_ACCESS:
            return payload ? R.assoc('appAccess', payload, state) : state;

        case ACTION_TYPES.SHOW_NOTIFICATION_MESSAGE:
            return R.assoc('messages', R.append(payload, state.messages), state);

        case ACTION_TYPES.CLEAR_NOTIFICATION_MESSAGES:
            return R.assoc('messages', [], state);

        case ACTION_TYPES.HIDE_NOTIFICATION_MESSAGE:
            return R.assoc(
                'messages',
                R.filter((msg) => msg.type !== payload.target.parentElement.parentElement.getAttribute('data-type'),
                    state.messages),
                state
            );

        default:
            return state;
    }
};
