import './user-cache.scss';
import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

class UserCache extends React.Component {
    constructor(props) {
        super(props);

        this.fetchCache = R.bind(this.fetchCache, this);
        this.handleDelete = R.bind(this.handleDelete, this);
        this.getMappedUserCache = R.bind(this.getMappedUserCache, this);

        this.state = {
            disabled: {},
        };

        if (R.isEmpty(props.adminReducer.userCache)) {
            props.fetchUserCache();
        }
    }

    fetchCache() {
        this.props.fetchUserCache();
    }
    handleDelete({ target }) {
        this.setState({
            disabled: R.assoc(target.datasetid, true, this.state.disabled),
        });

        this.props.expireUserCache(target.dataset);
    }

    getMappedUserCache() {
        const { userCache } = this.props.adminReducer;

        let lastAccount = null;
        const remapped = [];
        let records = [];

        const pushNewAcct = () => {
            if (!R.isNil(lastAccount)) {
                remapped.push({
                    id: lastAccount,
                    records,
                });
                records = [];
            }
        };

        R.forEach((record) => {
            const { account_id } = record;

            if (account_id !== lastAccount) {
                pushNewAcct();
                lastAccount = account_id;
            }
            records.push(R.omit(['account_id'], record));
        }, userCache);
        pushNewAcct();

        return remapped;
    }
    render() {
        const { userCache } = this.props.adminReducer;

        return (
            <div className='user-cache'>
                <h2>
                    User Cache
                    <Button
                        className="fa fa-refresh"
                        variant="link"
                        onClick={ this.fetchCache }/>
                </h2>
                { !R.isEmpty(userCache) && (
                    <Table>
                        <thead>
                            <tr>
                                <th className="account-heading">Account</th>
                            </tr>
                        </thead>
                        { R.map((acct) => (
                            <tbody key={ acct.id }>
                                <tr>
                                    <td>
                                        <Button disabled={ this.state.disabled[acct.id] }
                                            className="fa fa-trash"
                                            variant="link"
                                            data-account_id={ acct.id }
                                            data-added_date={ acct.records[0].added_date }
                                            onClick={ this.handleDelete }/>
                                        {acct.id}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    { R.map((k) => (
                                                        <th key={ k }>{ R.replace('_', ' ', k) }</th>
                                                    ), R.keys(acct.records[0]))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { R.map((record) => (
                                                    <tr key={ record.id }>
                                                        { R.map((key) => (
                                                            <td key={ key }>{ record[key] }</td>
                                                        ), R.keys(record)) }
                                                    </tr>
                                                ), acct.records) }
                                            </tbody>
                                        </Table>
                                    </td>
                                </tr>
                            </tbody>
                        ), this.getMappedUserCache()) }
                    </Table>
                )}
            </div>
        );
    }
}

UserCache.propTypes = {
    adminReducer: PropTypes.object,
    fetchUserCache: PropTypes.func,
    expireUserCache: PropTypes.func,
};

export default UserCache;
