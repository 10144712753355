import React from 'react';
import PropTypes from 'prop-types';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';

export const TableSearch = (props) => {
    //@TODO FormControl should be replaced with components/text-input
    return (
        <div>
            <h3>Search based on { props.name } Id</h3>
            <FormControl
                type="text"
                placeholder={ props.name + ' Id' }
                onChange={ props.handleChange }
                value={ props.value }
            />
            <Button variant="primary" onClick={ props.handleSubmit }>Load { props.name }</Button>
        </div>
    );
};

TableSearch.propTypes = {
    name: PropTypes.string,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    value: PropTypes.string,
};
