import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LabDisplay from './lab-display';
import { fetchAccountDetails } from '../../../../submodules/shared/actions/account-actions';

const mapStateToProps = (reducers) => {
    return reducers;
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchAccountDetails,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(LabDisplay);
