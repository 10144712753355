import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearNotifications, hideNotificationItem } from '../../actions/app-action-creators';
import { fetchAppAccess } from '../../actions/app-action-creators';

import App from './app';

const mapStateToProps = ({ appReducer }) => ({
    appReducer,
    messages: appReducer.messages,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    clearNotifications,
    hideNotificationItem,
    fetchAppAccess,
}, dispatch);

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;
