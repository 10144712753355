import ACTION_TYPES from './action-types';
import { getStudentProducts, getUserLabs, getLabsByProduct } from '../utils/api';
import { noop } from '../submodules/shared/utils/noop';

export const fetchStudentLabs = (payload) => {
    return (dispatch) => getStudentProducts(payload).then(({ data }) => {
        dispatch({
            type: ACTION_TYPES.CLEAR_NOTIFICATION_MESSAGES,
        });

        dispatch({
            type: ACTION_TYPES.UPDATE_STUDENT_RESULTS,
            payload: data,
        });
    }, () => {
        //TODO: should probably create an error messages constant file
        dispatch({
            type: ACTION_TYPES.SHOW_NOTIFICATION_MESSAGE,
            payload: { type: 'danger', message: 'No info found for the given Student ID' },
        });

        dispatch({
            type: ACTION_TYPES.UPDATE_STUDENT_RESULTS,
            payload: [],
        });

        dispatch({
            type: ACTION_TYPES.SET_STUDENT_FILTER,
            payload: '',
        });
    });
};

export const setStudentId = (id) => (dispatch) => {
    dispatch({
        type: ACTION_TYPES.UPDATE_STUDENT_RESULTS,
        payload: {},
    });
    dispatch({
        type: ACTION_TYPES.SET_STUDENT_FILTER,
        payload: id,
    });
};

export const findUserLabs = ({ id }) =>
    (dispatch) => getUserLabs(id).then(({ data }) => {
        dispatch({
            type: ACTION_TYPES.SET_STUDENT_LABS,
            payload: data,
        });
    }, noop);

export const findFilteredStudentLabs = ({ target }) => {
    return (dispatch) => getLabsByProduct(target.dataset).then(({ data }) => {
        dispatch({
            type: ACTION_TYPES.SET_FILTERED_STUDENT_LABS,
            payload: {
                product: target.dataset.product,
                data,
            },
        });
    });
};
