import ACTION_TYPES from './action-types';

import {
    getLabDetails,
    getLabItems,
    postLabDetails,
    getLabInstructions,
    postLabKeys,
    getProducts,
    getLabsByProduct,
} from '../utils/api';
import history from '../submodules/shared/utils/history';
import { PATH } from '../constants';
import { dateOr } from '../utils/format-date';

import * as R from 'ramda';

export const upsertLab = (payload) => {
    payload = R.mergeRight(payload, {
        live_event_start: dateOr(payload.live_event_start),
        live_event_end: dateOr(payload.live_event_end),
    });

    return (dispatch) => postLabDetails(payload)
        .then(({ result, data }) => {
            if (result === 'success') {
                //Clear the lab items so home will fetch new results when it loads
                dispatch({
                    type: ACTION_TYPES.UPDATE_LAB_RESULTS,
                    payload: [],
                });
                history.push(PATH.home);
            } else { //If validation doesn't pass...
                R.forEachObjIndexed((value, key) =>
                    dispatch({
                        type: ACTION_TYPES.SHOW_NOTIFICATION_MESSAGE,
                        payload: { type: 'danger', message: `${key}: ${value}` },
                    }),
                data);
                window.scrollTo(0, 0); //If we like doing this it should be moved into the action
            }
        });
};

export const fetchLabItems = (payload) =>
    (dispatch) => {
        dispatch({
            type: ACTION_TYPES.SET_LAB_FILTER,
            payload: '',
        });

        getLabItems(payload).then(({ data, pagination }) => {
            dispatch({
                type: ACTION_TYPES.UPDATE_LAB_RESULTS,
                payload: data,
            });

            dispatch({
                type: ACTION_TYPES.SET_LAB_PAGINATION,
                payload: { pagination, orderBy: payload.orderBy, dir: payload.dir },
            });
        }, () => {
            dispatch({
                type: ACTION_TYPES.UPDATE_LAB_RESULTS,
                payload: [],
            });
        });
    };

export const fetchLabDetails = (id) =>
    (dispatch) => getLabDetails(id).then(({ data }) => {
        dispatch({
            type: ACTION_TYPES.SET_LAB_FILTER,
            payload: id,
        });

        dispatch({
            type: ACTION_TYPES.SET_LAB_DETAILS,
            //payload: data, //original
            payload: R.head(data), //fix for express.js //we return an arary because labs endpoint can contain multiple
        });
    }, (err) => dispatch({
        type: ACTION_TYPES.SET_LAB_DETAILS_FAILED,
        payload: err,
    }));

export const clearLabDetails = () =>
    (dispatch) => {
        dispatch({
            type: ACTION_TYPES.CLEAR_LAB_DETAILS,
        });
        history.push(PATH.lab);
    };

export const fetchLabInstructions = (id) =>
    (dispatch) => getLabInstructions(id).then(({ data }) => {
        dispatch({
            type: ACTION_TYPES.SET_LAB_INSTRUCTIONS,
            payload: data,
        });
    }, () => dispatch({
        type: ACTION_TYPES.SHOW_NOTIFICATION_MESSAGE,
        payload: { type: 'danger', message: 'Could not find instructions' },
    }));

export const uploadLabKeys = (payload) =>
    (dispatch) => postLabKeys(payload).then(() => {
        dispatch({
            type: ACTION_TYPES.CERTS_UPLOADED,
        });
        history.push(PATH.labAction(payload.id, PATH.lab_actions.VIEW));
    });
export const fetchProducts = () =>
    (dispatch) => getProducts().then(({ data }) =>
        dispatch({
            type: ACTION_TYPES.SET_PRODUCTS,
            payload: data,
        }));

export const findLabsByProduct = ({ target }) =>
    (dispatch) => getLabsByProduct(target.dataset).then(({ data }) => {
        dispatch({
            type: ACTION_TYPES.SET_EVENT_LABS,
            payload: {
                product: target.dataset.product,
                data,
            },
        });
    });
