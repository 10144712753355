import React from 'react';
import PropTypes from 'prop-types';
import { dateOr } from '../../utils/format-date';

import ReactDateTimePicker from 'react-datetime-picker';

import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';

export const DateTimePicker = (props) => {
    const onChange = (date) =>
        props.onChange({
            target: {
                id: props.id,
                value: date ? date.toJSON() : '',
            },
        });

    return (
        <FormGroup>
            <FormLabel>
                { props.label }
            </FormLabel>
            <ReactDateTimePicker
                onChange={ onChange }
                value={ dateOr(props.value) }
            />
        </FormGroup>
    );
};

DateTimePicker.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
};