import React from 'react';
import PropTypes from 'prop-types';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import * as R from 'ramda';

import AdminTab from './tabs/admin-tab';
import LabTab from './tabs/lab-tab';
import FilteredTab from './tabs/filtered-tab';

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            key: 'labs',
        };

        this.handleSelect = R.bind(this.handleSelect, this);
    }

    handleSelect(key) {
        this.setState({ key });
    }

    render() {
        const {
            appReducer,
            adminReducer,
            studentReducer,
            eventReducer,
            setProductAccess,
            fetchAccountAccess,
            fetchProductList,
            setAccountAccess,
            createAccountAccess,
            fetchStudentLabs,
            fetchEventLabs,
            setStudentId,
            setEventId,
            findLabsByProduct,
            findUserLabs,
            findFilteredStudentLabs,
            fetchUserCache,
            expireUserCache,
        } = this.props;

        return (
            <div className="home">
                {R.pathOr(false, ['appAccess', 'read'], appReducer) ? (
                    <Tabs
                        activeKey={ this.state.key }
                        onSelect={ this.handleSelect }
                        id="controlled-tab-example"
                    >
                        <Tab eventKey="labs" title="Labs">
                            <LabTab
                                { ...R.pick(
                                    [
                                        'appReducer',
                                        'labReducer',
                                        'eventReducer',
                                        'fetchProducts',
                                        'fetchLabItems',
                                        'clearLabDetails',
                                    ],
                                    this.props
                                ) }
                            />
                        </Tab>
                        <Tab eventKey="events" title="Events">
                            <FilteredTab
                                name="Event"
                                appReducer={ appReducer }
                                reducer={ eventReducer }
                                fetchProducts={ fetchEventLabs }
                                setId={ setEventId }
                                productLabs={ eventReducer.productLabs }
                                fetchLabs={ findLabsByProduct }
                            />
                        </Tab>
                        <Tab eventKey="students" title="Students">
                            <FilteredTab
                                name="Student"
                                appReducer={ appReducer }
                                reducer={ studentReducer }
                                fetchProducts={ fetchStudentLabs }
                                setId={ setStudentId }
                                productLabs={ studentReducer.productLabs }
                                fetchLabs={ findFilteredStudentLabs }
                                findUserLabs={ findUserLabs }
                            />
                        </Tab>
                        {R.pathOr(false, ['appAccess', 'admin'], appReducer) ? (
                            <Tab eventKey="admin" title="Admin">
                                <AdminTab
                                    adminReducer={ adminReducer }
                                    appReducer={ appReducer }
                                    setProductAccess={ setProductAccess }
                                    fetchAccountAccess={ fetchAccountAccess }
                                    fetchProductList={ fetchProductList }
                                    setAccountAccess={ setAccountAccess }
                                    createAccountAccess={ createAccountAccess }
                                    fetchUserCache={ fetchUserCache }
                                    expireUserCache={ expireUserCache }
                                />
                            </Tab>
                        ) : null}
                    </Tabs>
                ) : null}
            </div>
        );
    }
}

Home.propTypes = {
    appReducer: PropTypes.object,
    adminReducer: PropTypes.object,
    labReducer: PropTypes.object,
    eventReducer: PropTypes.object,
    studentReducer: PropTypes.object,
    fetchProductList: PropTypes.func,
    setProductAccess: PropTypes.func,
    fetchAccountAccess: PropTypes.func,
    setAccountAccess: PropTypes.func,
    createAccountAccess: PropTypes.func,
    fetchProducts: PropTypes.func,
    fetchLabItems: PropTypes.func,
    fetchEventLabs: PropTypes.func,
    setEventId: PropTypes.func,
    setStudentId: PropTypes.func,
    fetchStudentLabs: PropTypes.func,
    findLabsByProduct: PropTypes.func,
    findUserLabs: PropTypes.func,
    fetchUserCache: PropTypes.func,
    expireUserCache: PropTypes.func,
    findFilteredStudentLabs: PropTypes.func,
    fetchLabs: PropTypes.func,
};

export default Home;
