import ACTION_TYPES from '../actions/action-types';
import * as R from 'ramda';

const INITIAL_EVENT_STATE = {
    filteredId: '',
    results: [],
    productLabs: {},
};

const eventReducer = (state = INITIAL_EVENT_STATE, { type, payload }) => {
    switch (type) {
        case ACTION_TYPES.SET_EVENT_FILTER:
            return R.assoc('filteredId', payload, state);

        case ACTION_TYPES.UPDATE_EVENT_RESULTS:
            return R.assoc('results', payload, state);

        case ACTION_TYPES.SET_EVENT_LABS:
            return R.assocPath(['productLabs', payload.product], payload.data, state);

        default:
            return state;
    }
};

export default eventReducer;
