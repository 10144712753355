import ACTION_TYPES from './action-types';
import {
    getAccountAccess,
} from '../utils/api';

export const notificationMessage = (payload) =>
    (dispatch) => dispatch({
        type: ACTION_TYPES.SHOW_NOTIFICATION_MESSAGE,
        payload,
    });

export const clearNotifications = () =>
    (dispatch) => dispatch({
        type: ACTION_TYPES.CLEAR_NOTIFICATION_MESSAGES,
    });

export const hideNotificationItem = (payload) =>
    (dispatch) => dispatch({
        type: ACTION_TYPES.HIDE_NOTIFICATION_MESSAGE,
        payload,
    });

export const fetchAppAccess = (payload) =>
    (dispatch) => {
        getAccountAccess(payload).then(({ data }) => {
            if (!data) {
                dispatch({
                    type: ACTION_TYPES.SHOW_NOTIFICATION_MESSAGE,
                    payload: {
                        type: 'danger',
                        message: 'You are not authorized.',
                    },
                });
            }
            dispatch({
                type: ACTION_TYPES.SET_APP_ACCESS,
                payload: data,
            });
        }, () => {
            //Error
        });
    };
