import React from 'react';
import PropTypes from 'prop-types';
import { dateOr } from '../../utils/format-date';

import ReactDatePicker from 'react-date-picker';

import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';

export const DatePicker = (props) => {
    //@TODO Bad practice - this will create onChange function on every render
    // - Not sure of a better way to do this as react-date-picker doesn't return id... May need to be a smart component
    const onChange = (date) =>
        props.onChange({
            target: {
                id: props.id,
                value: date ? date.toJSON() : '',
            },
        });

    return (
        <FormGroup>
            <FormLabel>
                { props.label }
            </FormLabel>
            <ReactDatePicker
                onChange={ onChange }
                value={ dateOr(props.value) }
            />
        </FormGroup>
    );
};

DatePicker.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
};
