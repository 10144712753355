import {
    API_BASE,
    API_CREDENTIALS,
} from './submodules/shared/constants-base';

const PATH = {
    base: '/',
    home: '/admin',
    lab: '/lab',
    labAction: (id, action) => id ? `/lab/${id}/${action}` : '/lab/:id/:action',
    eventView: '/event/:id',
    lab_actions: {
        VIEW: 'view',
        EDIT: 'edit',
        INSTRUCTIONS: 'instructions',
        CERTS: 'certs',
        LABSTART: 'lab_start',
    },
};

const NAVBAR_ITEMS = [{
    name: 'Home',
    path: PATH.home,
}];

const SORT_DIRECTIONS = {
    ASC: 'asc',
    DESC: 'desc',
};

const OVPN_PATH = 'ovpnadmin';

const ACCOUNT_DETAILS_PATH = 'account_details_admin';

export {
    API_BASE,
    API_CREDENTIALS,
    SORT_DIRECTIONS,
    NAVBAR_ITEMS,
    OVPN_PATH,
    PATH,
    ACCOUNT_DETAILS_PATH,
};
