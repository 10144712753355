import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { formatDBDate } from '../../utils/format-date';
import { PATH } from '../../constants';
import * as R from 'ramda';

const LabTable = ({ appAccess, labs, changeOrderBy, tableContext }) => (
    <Table striped bordered hover>
        <thead>
            <tr>
                <th onClick={ changeOrderBy } className={ changeOrderBy && 'sortable-field' }>ID</th>
                <th onClick={ changeOrderBy } className={ changeOrderBy && 'sortable-field' }>Name</th>
                <th onClick={ changeOrderBy } className={ changeOrderBy && 'sortable-field' }>Status</th>
                <th>Type</th>
                <th>Live Event (Start-End)</th>
                <th>OnDemand ID (Start-End)</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            { R.map((lab) => (
                <tr key={ lab.id }>
                    <td>{ lab.id }</td>
                    <td>{ lab.name }</td>
                    <td>{ lab.status }</td>
                    <td>{ lab.type }</td>
                    <td>{ formatDBDate(lab.live_event_start) } - { formatDBDate(lab.live_event_end) }</td>
                    <td>{ lab.on_demand_training_id_start } - { lab.on_demand_training_id_end }</td>
                    <td>
                        <ul>
                            <li>
                                <Link to={
                                    PATH.labAction(lab.id, PATH.lab_actions.VIEW) }>View
                                </Link>
                            </li>
                            { appAccess.write ? (
                                <li>
                                    <Link to={
                                        PATH.labAction(lab.id, PATH.lab_actions.EDIT) }>Edit
                                    </Link>
                                </li>
                            ) : null }
                            <li>
                                <Link to={
                                    PATH.labAction(lab.id, PATH.lab_actions.INSTRUCTIONS) }>Instructions
                                </Link>
                            </li>
                            { appAccess.write && tableContext === 'Event' ? (
                                <li>
                                    <Link to={
                                        PATH.labAction(lab.id, PATH.lab_actions.LABSTART) }>Lab 2 Start Date
                                    </Link>
                                </li>
                            ) : null }
                            { appAccess.write && lab.type === 'VPN' ? (
                                <li>
                                    <Link to={
                                        PATH.labAction(lab.id, PATH.lab_actions.CERTS) }>Upload cert/key
                                    </Link>
                                </li>
                            ) : null }
                        </ul>
                    </td>
                </tr>
            ), labs) }
        </tbody>
    </Table>
);

LabTable.propTypes = {
    appAccess: PropTypes.object,
    labs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            live_event_start: PropTypes.string,
            live_event_end: PropTypes.string,
            name: PropTypes.string,
            status: PropTypes.string,
            type: PropTypes.string,
        })
    ),
    changeOrderBy: PropTypes.func,
    tableContext: PropTypes.string,
};

export default LabTable;
