import './lab-display.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getOVPNCert } from '../../utils/api-shared';
import { textToFile } from '../../utils/text-to-file';
import { dateBeforeNow } from '../../utils/date-before-now';
import { remapCourses } from '../../utils/remap-courses';
import Expandable from '../expandable';
import ContactUs from '../contact-us';
import Downloading from '../downloading';
import Alert from 'react-bootstrap/Alert';

import * as R from 'ramda';

class LabDisplay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showAlert: false,
            groupedByCourse: remapCourses(props.labResults),
            nname: '', //@TODO this used to just be `name,` is it ok to be `name:'',`?
            sansAccountID: props.accountReducer.sansAccountID,
            sansEmail: props.accountReducer.sansEmail,
            downloading: {},
        };

        if (R.isNil(props.accountReducer.sansAccountID)) {
            props.fetchAccountDetails();
        }

        this.downloadOVPN = R.bind(this.downloadOVPN, this);
        this.renderOVPN = R.bind(this.renderOVPN, this);
        this.renderInstructionSet = R.bind(this.renderInstructionSet, this);
        this.renderCourse = R.bind(this.renderCourse, this);
        this.renderLab = R.bind(this.renderLab, this);
        this.toggleAlert = R.bind(this.toggleAlert, this);
    }

    componentDidUpdate({ labResults, accountReducer }) {
        const currentResults = this.props.labResults;

        if (currentResults && labResults !== currentResults) {
            this.setState({
                groupedByCourse: remapCourses(currentResults),
            });
        }

        const currentAccount = this.props.accountReducer;

        if (currentAccount && JSON.stringify(accountReducer) !== JSON.stringify(currentAccount)) {
            this.setState({
                nname: currentAccount.name,
                sansAccountID: currentAccount.sansAccountID,
                sansEmail: currentAccount.sansEmail,
            });
        }
    }

    /**
     * handle click of download ovpn. Kick off download unless error -then show alert
     * @param {object} event
     */
    downloadOVPN({ target: { dataset: { os, labId }}}) {
        this.setState({
            downloading: R.assoc(labId + '-' + os, true, this.state.downloading),
        });

        getOVPNCert(labId, os)
            .then(({ filename, data }) => {
                const blob = new Blob([data], { type: 'text/plain' });

                this.setState({
                    downloading: R.dissoc(labId + '-' + os, this.state.downloading),
                });
                textToFile(blob, filename);
            })
            .catch(() => {
                this.toggleAlert();
                this.setState({
                    downloading: R.dissoc(labId + '-' + os, this.state.downloading),
                });
            });
    }

    /**
     * Render the OVPN buttons for each OS
     * @param {object} lab
     * @return {object}
     */

    renderOVPN({ id, nname, lab_start, ovpn_windows, ovpn_linux }) {
        const validOS = R.concat(
            ovpn_windows ? ['Windows'] : [],
            ovpn_linux ? ['Mac', 'Linux'] : []
        );

        if (R.isEmpty(validOS)) {
            return null;
        }

        const formatDate = (date) => {
            const jd = new Date(date);

            return R.join('/', [jd.getMonth() + 1, jd.getDate(), jd.getFullYear()]);
        };

        return (<div className={ 'ovpn-downloads' + (dateBeforeNow(lab_start) ? '' : ' disabled') }>
            <span className="available">
               Download OVPN Key for:
            </span>
            <span className="unavailable">
                <b>OVPN files will be available on { formatDate(lab_start) }:</b>
            </span>
            <ul>
                { R.map((os) => (
                    <li key={ os }>
                        { this.state.downloading[id + '-' + os] ? (
                            <Downloading/>
                        ) : (
                            <div
                                className={ `os-${os}` }
                                onClick={ dateBeforeNow(lab_start) ? this.downloadOVPN : null }
                                data-lab-id={ id }
                                data-lab-name={ nname }
                                data-os={ os }
                                alt={ os }
                            >
                            </div>
                        )}
                    </li>
                ), validOS)}
            </ul>
        </div>);
    }

    /**
     * @func renderInstructionSet
     * @description returns an array of formatted instructions
     * @param  {array} instructions array of instrucntion objects
     * @return {array}              description
     */
    renderInstructionSet(instructions) {
        let count = 0;

        return R.map(({ field_lab_instruction_display_na, field_lab_instruction }) => (
            <div key={ count++ }>
                <h5>{ field_lab_instruction_display_na }</h5>
                <div dangerouslySetInnerHTML={ { __html: field_lab_instruction } }/>
            </div>
        ), instructions);
    }

    /**
     * @func renderLab
     * @description returns an html object to represent a lab
     * @param  {object} lab lab containing lab info and an array of instructions
     * @return {object} an html object to represent a lab
     */
    renderLab(lab) {
        const instructions = R.dissoc('Overview', lab.instructions);
        const lab_name = lab.name.replace('GEN', 'OD');

        //Don't display expandable if lab doesn't ahve instructions other than overview
        return R.isEmpty(instructions) ? null : (
            <Expandable
                key={ lab.id }
                title={ lab_name }
                subTitle={ this.renderOVPN(lab) }
            >
                {
                    R.map((field_instruction_type) => (
                        <Expandable
                            key={ field_instruction_type }
                            title={ field_instruction_type }
                        >
                            { this.renderInstructionSet(instructions[field_instruction_type]) }
                        </Expandable>
                    ), R.keys(instructions))
                }
            </Expandable>
        );
    }

    /**
     * @func renderCourse
     * @description returns an html object to represent a course
     * @param  {array} records records of the courses
     * @return {object} an html object to represent a course
     */
    renderCourse(records) {
        const {
            product_id,
            course_name,
            instructions,
            instructions: {
                Overview,
            },
        } = records[0];

        const children = R.length(R.keys(instructions)) === 0 ? (
            <h3>No instructions found for this lab</h3>
        ) : R.prepend(this.renderInstructionSet(Overview), R.map(this.renderLab, records));

        return (
            <Expandable
                key={ product_id }
                title={ course_name || 'Unnamed course' }
                defaultExpanded={ R.length(R.keys(this.state.groupedByCourse)) < 3 }
            >
                { children }
            </Expandable>
        );
    }

    /**
     * Toggle the flag to show/hide the alert
     */
    toggleAlert() {
        this.setState({
            showAlert: !this.state.showAlert,
        });
    }

    render() {
        const {
            toggleAlert,
            renderCourse,
            state: { groupedByCourse, showAlert, nname, sansEmail, sansAccountID },
        } = this;

        return (
            <div className="lab-display">
                { showAlert && (
                    <Alert variant="danger" onDismiss={ toggleAlert }>
                        <p>OVPN generation not currently available for this lab.</p>
                        <p>
                            Please contact
                            <a href="mailto:Virtual-Labs-Support@sans.org">Virtual-Labs-Support@sans.org</a>
                        </p>
                    </Alert>
                )}

                { R.isEmpty(groupedByCourse) ? (
                    <div className="no-labs-content">
                        <p>
                            There are no virtual labs associated with your account. If you believe this is an error
                            please contact
                            <a href="mailto:Virtual-Labs-Support@sans.org">Virtual-Labs-Support@sans.org</a>
                            for assistance.
                        </p>
                        <p> If you have recently registered for an OnDemand course but haven’t started it please return to <a href="https://ondemand.sans.org">ondemand.sans.org</a> and begin the course. </p>
                    </div>
                ) : R.map(renderCourse, R.values(groupedByCourse)) }
                { !R.isNil(sansEmail) && (
                    <ContactUs
                        name={ nname }
                        sansAccountID={ sansAccountID }
                        sansEmail={ sansEmail }
                    />
                )}
            </div>
        );
    }
}

LabDisplay.propTypes = {
    accountReducer: PropTypes.object,
    labResults: PropTypes.array,
    fetchAccountDetails: PropTypes.func,
};

export default LabDisplay;
