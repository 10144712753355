import React from 'react';
import PropTypes from 'prop-types';
import './boolean-box.scss';

import InputGroup from 'react-bootstrap/InputGroup';
import FormGroup from 'react-bootstrap/FormGroup';

export const BooleanBox = (props) => (
    <FormGroup
        className="boolean-box"
        controlId={ props.id }
    >
        <InputGroup.Checkbox
            id={ props.field }
            name={ props.field }
            checked={ !!props.checked }
            onChange={ props.onChange }
        />
        <label className="input-group-text" htmlFor={ props.field }>{ props.label }</label>
    </FormGroup>
);

BooleanBox.propTypes = {
    id: PropTypes.string,
    field: PropTypes.string,
    label: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
};
