import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import BooleanBox from '../../../../components/boolean-box';
import TextInput from '../../../../components/text-input';

const newUser = [{
    id: 'new',
    email: '',
    read: 1,
    write: 0,
    admin: 0,
}];

class UserAccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountAccess: this.props.adminReducer.accountAccess,
            saveButton: {},
            newUser,
        };

        if (!this.state.accountAccess.length) {
            props.fetchAccountAccess();
        }

        this.handleSave = R.bind(this.handleSave, this);
        this.handleAdd = R.bind(this.handleAdd, this);
        this.handlePermission = R.bind(this.handlePermission, this);
        this.handleEmail = R.bind(this.handleEmail, this);
    }

    componentDidUpdate({ adminReducer }) {
        if (adminReducer.accountAccess !== this.props.adminReducer.accountAccess) {
            this.setState({
                accountAccess: this.props.adminReducer.accountAccess,
                newUser,
            });
        }
    }

    QuickBool(user, field) {
        return (
            <BooleanBox
                id={ String(user.id) }
                field={ user.id + '-' + field }
                checked={ !!user[field] }
                onChange={ this.handlePermission }
            />
        );
    }

    QuickText(user, field) {
        const placeHolders = {
            account_id: 'SANS Account ID',
            email: 'E Mail',
        };

        return (<TextInput
            id={ user.id + '-' + field }
            value={ user[field] }
            placeholder={ placeHolders[field] }
            onChange={ this.handleEmail }
        />);
    }

    handlePermission({ target }) {
        const [id, field] = R.split('-', target.id);

        this.setState({
            saveButton: R.assoc(id, true, this.state.saveButton),
            [id === 'new' ? 'newUser' : 'accountAccess']: R.map((user) => {
                if (String(user.id) === id) {
                    //Make sure privilages prior privilages are (un)selected
                    if (target.checked) {
                        switch (field) {
                            case 'admin':
                                user.admin = 1;
                                //falls through
                            case 'write':
                                user.write = 1;
                                //falls through
                            case 'read':
                            default:
                                user.read = 1;
                        }
                    } else {
                        switch (field) {
                            case 'read':
                                user.read = 0;
                                //falls through
                            case 'write':
                                user.write = 0;
                                //falls through
                            case 'admin':
                            default:
                                user.admin = 0;
                        }
                    }
                    return R.assoc(field, target.checked ? 1 : 0, user);
                }
                return user;
            }, id === 'new' ? this.state.newUser : this.state.accountAccess),
        });
    }

    handleEmail({ target }) {
        const [id, field] = R.split('-', target.id);

        this.setState({
            saveButton: R.assoc(id, true, this.state.saveButton),
            [id === 'new' ? 'newUser' : 'accountAccess']: R.map((user) => {
                if (String(user.id) === id) {
                    return R.assoc(field, target.value, user);
                }
                return user;
            }, this.state[id === 'new' ? 'newUser' : 'accountAccess']),
        });
    }

    handleAdd() {
        this.props.createAccountAccess(this.state.newUser[0]);
    }

    handleSave({ target }) {
        const data = R.find(R.propEq('id', parseInt(target.dataset.id, 10)), this.state.accountAccess);

        this.setState({
            saveButton: R.assoc(target.dataset.id, false, this.state.saveButton),
        });

        this.props.setAccountAccess(data);
    }

    render() {
        return (
            <div>
                <h2>User Access</h2>
                <Table>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Email (for readability)</th>
                            <th>Read</th>
                            <th>Write</th>
                            <th>Admin</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        { R.map((user) => (
                            <tr key={ user.id }>
                                <td>{ user.account_id }</td>
                                <td>{ this.QuickText(user, 'email') }</td>
                                <td>{ this.QuickBool(user, 'read') }</td>
                                <td>{ this.QuickBool(user, 'write') }</td>
                                <td>{ this.QuickBool(user, 'admin') }</td>
                                <td>
                                    <Button data-id={ user.id }
                                        disabled={ !this.state.saveButton[user.id] || user.email.length < 13 }
                                        onClick={ this.handleSave }>
                                        Save
                                    </Button>
                                </td>
                            </tr>
                        ), this.state.accountAccess) }
                        { R.map((user) => (
                            <tr key={ user.id }>
                                <td>{ this.QuickText(user, 'account_id') }</td>
                                <td>{ this.QuickText(user, 'email') }</td>
                                <td>{ this.QuickBool(user, 'read') }</td>
                                <td>{ this.QuickBool(user, 'write') }</td>
                                <td>{ this.QuickBool(user, 'admin') }</td>
                                <td>
                                    <Button disabled={ this.state.newUser[0].email.length < 13 }
                                        onClick={ this.handleAdd }>
                                        Add
                                    </Button>
                                </td>
                            </tr>
                        ), this.state.newUser)}
                    </tbody>
                </Table>
            </div>
        );
    }
}

UserAccess.propTypes = {
    adminReducer: PropTypes.object,
    fetchAccountAccess: PropTypes.func,
    createAccountAccess: PropTypes.func,
    setAccountAccess: PropTypes.func,
};

export default UserAccess;
