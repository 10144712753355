import './downloading.scss';
import React from 'react';
import PropTypes from 'prop-types';

export const Downloading = ({ show = true }) => show ? (
    <div className="spinner">
        <div className="dot1 dot"></div>
        <div className="dot2 dot"></div>
        <div className="dot3 dot"></div>
        <div className="dot4 dot"></div>
        <div className="dot5 dot"></div>
        <div className="dot6 dot"></div>
        <div className="dot7 dot"></div>
        <div className="dot8 dot"></div>
        <div className="dot9 dot"></div>
        <div className="dot10 dot"></div>
        <div className="dot11 dot"></div>
        <div className="dot12 dot"></div>
    </div>
) : null;

Downloading.propTypes = {
    show: PropTypes.bool,
};
