import './loading.scss';
import React from 'react';
import PropTypes from 'prop-types';

export const Loading = ({ message }) => (
    <div className="loading">
        <div>
            <div className="indicator">
                <div className="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div className="message">{ message }</div>
        </div>
    </div>
);

Loading.propTypes = {
    message: PropTypes.string,
};
