import ACTION_TYPES from '../actions/action-types';
import * as R from 'ramda';

const INITIAL_LAB_STATE = {
    filteredLabId: '',
    labResults: [],
    currentLab: {},
    productResults: [],
};

const labReducer = (state = INITIAL_LAB_STATE, { type, payload }) => {
    switch (type) {
        case ACTION_TYPES.SET_LAB_FILTER:
            return R.assoc('filteredLabId', payload, state);

        case ACTION_TYPES.UPDATE_LAB_RESULTS:
            return R.assoc('labResults', payload, state);

        case ACTION_TYPES.SET_LAB_DETAILS:
            //SET_LAB_INSTRUCTIONS occurs before this, we need to use merge
            return R.assoc('currentLab', R.mergeRight(state.currentLab, payload), state);

        case ACTION_TYPES.CLEAR_LAB_DETAILS:
            return R.assoc('currentLab', {}, state);

        case ACTION_TYPES.SET_LAB_INSTRUCTIONS:
            return R.assocPath(['currentLab', 'instructions'], payload, state);

        case ACTION_TYPES.SET_PRODUCTS:
            return R.assoc('productResults', payload, state);

        default:
            return state;
    }
};

export default labReducer;
