import { noop } from '../submodules/shared/utils/noop';

/**
 * change db format of yyyy-mm-dd to mm/dd/yyyy
 * @param {string} date
 * @return {string}
 */
export const formatDBDate = (date) => {
    if (date) {
        const d = date.split('-');

        return [d[1], d[2], d[0]].join('/');
    }
    return noop();
};
/**
 *
 * @param {string} date
 * @return {bool} if the date is valid
 */
const isValidDate = (date) =>
    !isNaN(new Date(date).getDate());

/**
 * Get date without assuming timezone differences
 * - Noticed an issue where datepicker would display 8/17/1986 when the date `1985-08-18T00:00:00.000Z` was passed in
 * @param {string} date
 * @return {date} updated date
 */
export const getUTCDate = (date) => {
    const jsDate = new Date(date);
    const dateFormat = new Date(`${jsDate.getUTCMonth() + 1}/${jsDate.getUTCDate()}/${jsDate.getFullYear()}`);

    return isValidDate(date) ? dateFormat : '';
};

/**
 * cast date or return or
 * @param {string} date the date to be cast
 * @param {string} or what to return if not a valid date
 * @return { string }
 */
export const dateOr = (date, or = '') =>
    isValidDate(date) ? getUTCDate(date) : or;
