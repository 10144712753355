import './lab-edit.scss';

import React from 'react';
import PropTypes from 'prop-types';
import BooleanBox from '../../components/boolean-box';
import TextInput from '../../components/text-input';
import ButtonInput from '../../components/button-input';
import SingleSelect from '../../components/single-select';
import DatePicker from '../../components/date-picker';
import * as R from 'ramda';

import Well from '../../components/well';
import Button from 'react-bootstrap/Button';
import {FIELD_LABELS, FIELD_PLACEHOLDERS, LAB_FIELDS} from './lab-constants';

class LabEdit extends React.Component {
    constructor(props) {
        super(props);
        if (!props.labReducer.productResults.length) {
            props.fetchProducts();
        }

        this.state = R.pick(LAB_FIELDS, props.labReducer.currentLab);

        this.handleFormChanges = R.bind(this.handleFormChanges, this);
        this.handleUpsert = R.bind(this.handleUpsert, this);
        this.QuickInput = R.bind(this.QuickInput, this);
        this.QuickFile = R.bind(this.QuickFile, this);
        this.QuickBool = R.bind(this.QuickBool, this);
        this.handleFiles = R.bind(this.handleFiles, this);
    }

    componentDidUpdate({ labReducer }) {
        const { currentLab } = this.props.labReducer;

        if (JSON.stringify(labReducer.currentLab) !== JSON.stringify(currentLab)) {
            this.setState(R.pick(LAB_FIELDS, currentLab));
        }
    }

    handleFiles({ target }) {
        const reader = new FileReader();
        const field = target.id;

        reader.onload = () =>
            this.setState({
                [field]: reader.result,
            });
        reader.readAsText(target.files[0]);
    }

    QuickFile(field) {
        return (
            <TextInput
                id={ field }
                type="file"
                value={ this.state[field] }
                label={ FIELD_LABELS[field] }
                onChange={ this.handleFiles }
                accept='.json'
            />
        );
    }

    handleFormChanges({ target }) {
        if (target.type === 'checkbox') {
            if (target.id === 'pfSense_format' && target.checked) {
                this.setState({ pfSense_tls: 1 });
            }
            if (target.id === 'ovpn_linux' && target.checked) {
                this.setState({ ovpn_linux_resolved: 1 });
            }
            target = R.assoc('value', target.checked ? 1 : 0, target);
        } else if (target.id === 'type' && target.value !== 'VPN') {
            //unset Exceptions
            this.setState({
                tls_cipher_exception_linux: 0,
                tls_cipher_exception_windows: 0,
            });
        }
        this.setState({ [target.id]: target.value });
    }

    handleUpsert() {
        this.props.upsertLab(this.state);
    }

    QuickInput(field, type) {
        return (
            <TextInput
                id={ field }
                value={ this.state[field] }
                type={ type }
                label={ FIELD_LABELS[field] }
                placeholder={ FIELD_PLACEHOLDERS[field] }
                onChange={ this.handleFormChanges }
            />
        );
    }

    QuickBool(field) {
        return (
            <BooleanBox
                id={ field }
                field={ field }
                label={ FIELD_LABELS[field] }
                checked={ !!this.state[field] }
                onChange={ this.handleFormChanges }
            />
        );
    }
    render() {
        const {
            props: { labReducer },
            state: { blueprint_file },
            QuickInput,
            QuickBool,
            QuickFile,
            state,
        } = this;

        return (
            <Well className="lab-edit">
                <h2>Add/Edit Labs here</h2>
                <div className="form-group">
                    <label>ID:</label> { this.state.id || 'an ID will be generated upon save' }
                </div>
                { QuickInput('name') }
                <SingleSelect
                    id="status"
                    label={ FIELD_LABELS.status }
                    selected={ state.status }
                    options={ [{
                        label: 'Disabled',
                        value: 'disabled',
                    }, {
                        label: 'Deleted',
                        value: 'deleted',
                    }, {
                        label: 'Legacy',
                        value: 'legacy',
                    }, {
                        label: 'Current',
                        value: 'current',
                    }, {
                        label: 'Development',
                        value: 'development',
                    }] }
                    onChange={ this.handleFormChanges }
                />
                <SingleSelect
                    id="product_id"
                    label={ FIELD_LABELS.product_id }
                    selected={ state.product_id }
                    options={ R.map((product) => ({ value: product.id, label: product.name }),
                        labReducer.productResults)
                    }
                    onChange={ this.handleFormChanges }
                />
                <SingleSelect
                    id="type"
                    label={ FIELD_LABELS.type }
                    selected={ state.type }
                    options={ [{
                        label: 'VPN',
                        value: 'VPN',
                    }, {
                        label: 'LODS',
                        value: 'LODS',
                    }, {
                        label: 'LODS_CLOUD',
                        value: 'LODS_CLOUD',
                    }, {
                        label: 'HTTP_AUTH',
                        value: 'HTTP_AUTH',
                    }, {
                        label: 'NETWARS',
                        value: 'NETWARS',
                    }, {
                        label: 'PYWARS',
                        value: 'PYWARS',
                    }] }
                    onChange={ this.handleFormChanges }
                />
                <SingleSelect
                    id="section"
                    label={ FIELD_LABELS.section }
                    selected={ state.section }
                    options={ [{
                        label: '1',
                        value: '1',
                    }, {
                        label: '6',
                        value: '6',
                    }] }
                    onChange={ this.handleFormChanges }
                />
                { QuickInput('on_demand_training_id_start'/*, 'number' //@TODO */) }
                { QuickInput('on_demand_training_id_end'/*, 'number' //@TODO */) }
                { QuickInput('courseware_start') }
                { QuickInput('courseware_end') }
                <DatePicker
                    id="live_event_start"
                    label={ FIELD_LABELS.live_event_start }
                    value={ state.live_event_start }
                    onChange={ this.handleFormChanges }
                />
                <DatePicker
                    id="live_event_end"
                    label={ FIELD_LABELS.live_event_end }
                    value={ state.live_event_end }
                    onChange={ this.handleFormChanges }
                />
                { this.state.type === 'VPN' &&
                    (<div className="Well exceptions">
                        <h3>OVPN Exceptions</h3>
                        { QuickBool('tls_cipher_exception_linux')}
                        { QuickBool('tls_cipher_exception_windows')}
                    </div>)
                }
                <div className="Well includes">
                    <h3>Event Type Includes</h3>
                    { QuickBool('include_ondemand')}
                    { QuickBool('include_vlive')}
                    { QuickBool('include_simulcast')}
                    { QuickBool('include_mentor')}
                    { QuickBool('include_community')}
                    { QuickBool('include_conference')}
                    { QuickBool('include_mpo')}
                </div>
                { state.type === 'NETWARS' && (
                    <div className="Well netwars-fields">
                        <h3> { state.type } </h3>
                        { QuickInput('game_name') }
                        { QuickInput('host_name') }
                        { QuickInput('auth_key') }
                        { QuickInput('s3_bucket') }
                        { R.isNil(blueprint_file) || R.isEmpty(blueprint_file) ? QuickFile('blueprint_file') : (
                            <ButtonInput
                                id="blueprint_file"
                                label={ FIELD_LABELS.blueprint_file }
                                onClick={ this.handleFormChanges }
                                value=""
                                text="Remove File" >
                            </ButtonInput>
                        ) }
                        <DatePicker
                            id="course_start_date"
                            label={ FIELD_LABELS.course_start_date }
                            value={ state.course_start_date }
                            onChange={ this.handleFormChanges }
                        />
                        { QuickBool('auto_start')}
                    </div>
                )}
                { state.type === 'VPN' && (
                    <div className="Well vpn-fields">
                        <h3> { state.type } </h3>
                        { this.state.include_simulcast === 1 && QuickInput('parent'/*, 'number' //@TODO */) }
                        { QuickBool('sim_lab') }
                        { QuickInput('default_fqdn') }
                        { QuickBool('pfSense_format') }
                        { state.pfSense_format === 1 && (
                            <div className="ml-4">
                                { QuickBool('pfSense_tls') }
                            </div>
                        )}
                        { QuickBool('ovpn_linux') }
                        { state.ovpn_linux === 1 && (
                            <div className="ml-4">
                                { QuickBool('ovpn_linux_resolved') }
                            </div>
                        )}
                        { QuickBool('ovpn_windows') }
                        { QuickInput('ip_address') }
                        { QuickInput('gateway_ip') }
                        { QuickInput('gateway_name') }
                    </div>
                )}
                { state.type === 'LODS_CLOUD' && (
                    <div className="Well lods-cloud-fields">
                        <h3> { state.type } </h3>
                        { QuickBool('shared_environment') }
                        { QuickInput('lab_series') }
                    </div>
                )}
                <Button variant="success" onClick={ this.handleUpsert }>
                    { R.pathOr(false, ['currentLab', 'id'], labReducer) ? 'Update' : 'Add' } Lab
                </Button>
            </Well>
        );
    }
}

LabEdit.propTypes = {
    labReducer: PropTypes.object,
    fetchProducts: PropTypes.func,
    upsertLab: PropTypes.func,
    match: PropTypes.object,
};

export default LabEdit;
