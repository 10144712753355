import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';

/**
 * Putting the onChange in the FormGroup instead of on FormControl throws react error
 * * You provided a `value` prop to a form field without an `onChange` handler.
 * * This will render a read-only field. If the field should be mutable use `defaultValue`.
 * * Otherwise, set either `onChange` or `readOnly`.
 */

/**
 * @param {object} props
 * @return {object}
 */
export const InputWrapper = (props) => (
    <FormGroup
        controlId={ props.id }
    >
        <FormLabel>
            { props.label }
        </FormLabel>

        { props.children }

    </FormGroup>
);

InputWrapper.propTypes = {
    children: PropTypes.element,
    id: PropTypes.string,
    label: PropTypes.string,
};
