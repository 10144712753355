import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import accountReducer from '../submodules/shared/reducers/account-reducer';

import adminReducer from './admin-reducer';
import appReducer from './app-reducer';
import labReducer from './lab-reducer';
import eventReducer from './event-reducer';
import studentReducer from './student-reducer';

const root = combineReducers({
    accountReducer,
    adminReducer,
    appReducer,
    labReducer,
    eventReducer,
    studentReducer,
    routing: routerReducer,
});

export default root;
