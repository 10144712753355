import './expandable.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';

import * as R from 'ramda';

import FontAwesome from 'react-fontawesome';

class Expandable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: !!props.defaultExpanded,
        };

        this.toggle = R.bind(this.toggle, this);
    }

    toggle() {
        this.setState({
            expanded: !this.state.expanded,
        });
    }

    render() {
        const { title, subTitle, children } = this.props;

        return (
            <Card
                className="expandable"
            >
                <Card.Header
                    className="title"
                >
                    <span onClick={ this.toggle } className="expandable-target">
                        <FontAwesome name={ this.state.expanded ? 'minus' : 'plus' } />
                        { title }
                    </span>
                    { subTitle }
                </Card.Header>
                <Collapse in={ this.state.expanded }>
                    <Card.Body>
                        <Card.Text>
                            { children }
                        </Card.Text>
                    </Card.Body>
                </Collapse>
            </Card>
        );
    }
}

Expandable.propTypes = {
    defaultExpanded: PropTypes.bool,
    title: PropTypes.string,
    subTitle: PropTypes.object,
    children: PropTypes.array,
};

export default Expandable;
