import ACTION_TYPES from '../actions/action-types';
import * as R from 'ramda';

const INITIAL_STUDENT_STATE = {
    filteredId: '',
    results: [],
    labs: [],
    productLabs: {},
};

const studentReducer = (state = INITIAL_STUDENT_STATE, { type, payload }) => {
    switch (type) {
        case ACTION_TYPES.SET_STUDENT_FILTER:
            return R.assoc('filteredId', payload, state);

        case ACTION_TYPES.UPDATE_STUDENT_RESULTS: {
            // removes the time stamps from the event_start & event_end dates
            R.forEach((res) => {
                if (res.event_start && res.event_end) {
                    res.event_start = res.event_start.slice(0, 10);
                    res.event_end = res.event_end.slice(0, 10);
                }
            }, payload);
            return R.assoc('results', payload, state);
        }

        case ACTION_TYPES.SET_STUDENT_LABS:
            return R.assoc('labs', payload, state);

        case ACTION_TYPES.SET_FILTERED_STUDENT_LABS: {
            const filteredLabs = R.innerJoin((data, lab) => data.id === lab.lab_id, payload.data, state.labs);

            return R.assocPath(['productLabs', payload.product], filteredLabs, state);
        }

        default:
            return state;
    }
};

export default studentReducer;
