import React from 'react';
import { PropTypes } from 'prop-types';
import Well from '../../components/well';
import FormControl from 'react-bootstrap/FormControl';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import FormGroup from 'react-bootstrap/FormGroup';

import * as R from 'ramda';

class LabCerts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ca_keys: '',
            ca_certs: '',
            tls_auth: '',
        };
        this.handleTextFieldChange = R.bind(this.handleTextFieldChange, this);
        this.uploadCerts = R.bind(this.uploadCerts, this);
    }

    handleTextFieldChange({ target }) {
        this.setState({
            [target.name]: target.value,
        });
    }

    uploadCerts() {
        const { id } = this.props.labReducer.currentLab;

        if (this.state.ca_keys !== '' && typeof id !== 'undefined') {
            if (this.props.labReducer.currentLab.pfSense_format === 0 ||
                this.props.labReducer.currentLab.pfSense_tls === 0 ||
                this.state.tls_auth !== '') {
                this.props.uploadLabKeys(R.mergeRight({ id }, this.state));
            }
        }
    }

    render() {
        const { labReducer:
            {
                currentLab,
            },
        } = this.props;

        return (
            <div>
                <Well>
                    <h2>{ R.propOr('Loading...', 'name', currentLab) }</h2>
                    <h3>Upload Key/Cert</h3>
                </Well>
                <Card>
                    <FormGroup>
                        <FormControl
                            as="textarea"
                            placeholder="CA Key"
                            onChange={ this.handleTextFieldChange }
                            name='ca_keys'
                            rows='4'
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControl
                            as="textarea"
                            placeholder="CA Cert"
                            onChange={ this.handleTextFieldChange }
                            name='ca_certs'
                            rows='4'
                        />
                    </FormGroup>
                    { this.props.labReducer.currentLab.pfSense_format === 1 &&
                      this.props.labReducer.currentLab.pfSense_tls === 1 &&
                        <FormGroup>
                            <FormControl
                                as="textarea"
                                placeholder="TLS auth"
                                onChange={ this.handleTextFieldChange }
                                name='tls_auth'
                                rows='4'
                            />
                        </FormGroup>
                    }
                    <Button
                        variant="success"
                        onClick={ this.uploadCerts }
                    >
                        Upload Keys
                    </Button>
                </Card>
            </div>
        );
    }
}

LabCerts.propTypes = {
    labReducer: PropTypes.object,
    uploadLabKeys: PropTypes.func,
};

export default LabCerts;
