import {
    handledGetFetch,
    handledPostFetch,
} from '../submodules/shared/utils/api-base';

import { getAccountDetails } from '../submodules/shared/utils/api-shared';
import { API_BASE } from '../constants';
import * as R from 'ramda';

export const getLabItems = ({ orderBy, dir, where = {}}) =>
    handledGetFetch(`${ API_BASE }/labs?order_by=${ orderBy }&dir=${ dir }` +
        (where.product_id ? '&product_id=' + where.product_id : ''));

export const getLabDetails = (id) =>
    handledGetFetch(`${ API_BASE }/labs/${ id }`);

export const postLabDetails = (payload) =>
    handledPostFetch(`${ API_BASE }/labs/` + R.propOr('', 'id', payload), payload);

export const getEventProducts = (eventId) =>
    handledGetFetch(`${ API_BASE }/event/${ eventId }`);

export const getStudentProducts = (studentId) =>
    handledGetFetch(`${ API_BASE }/account/${ studentId }`);

export const getLabInstructions = (labId) =>
    handledGetFetch(`${ API_BASE }/instructions/${ labId }`);

export const postLabKeys = (payload) =>
    handledPostFetch(`${ API_BASE }/labscerts`, payload);

export const getProducts = () =>
    handledGetFetch(`${ API_BASE }/lab_products`);

export const getLabsByProduct = ({ product, start, type }) =>
    handledGetFetch(`${ API_BASE }/labs?product_id=${product}&start_date=${start}&event_type=${type}`);

export const getProductList = () =>
    handledGetFetch(`${ API_BASE }/product_access`);

export const postProductAccess = (payload) =>
    handledPostFetch(`${ API_BASE }/product_access/${ payload.id }`, payload);

export const getAccountAccess = (payload) =>
    handledGetFetch(`${ API_BASE }/account_access/` + R.propOr('', 'accountId', payload));

export const postAccountAccess = (payload) =>
    handledPostFetch(`${ API_BASE }/account_access/${ payload.id }`, payload);

export const putAccountAccess = (payload) =>
    handledPostFetch(`${ API_BASE }/account_access`, payload);

export const getUserCache = () =>
    handledGetFetch(`${ API_BASE }/user_cache`);

export const postUserCache = (payload) =>
    handledPostFetch(`${ API_BASE }/user_cache/${ payload.account_id }`, payload);

export const getUserLabs = (userId) => {
    return handledGetFetch(`${ API_BASE }/users/${ userId }`);
};

export const getOVPNDownloads = (type = 'day', units = 7) => {
    return handledGetFetch(`${ API_BASE }/ovpn_downloads/?units=${ units }&type=${ type }`);
};

export const getPageVisits = (type = 'day', units = 7) => {
    return handledGetFetch(`${ API_BASE }/page_visits/?units=${ units }&type=${ type }`);
};

export const getEventProductId = (event_id, product_id) => {
    return handledGetFetch(`
        ${ API_BASE }/event_product/event_product_id?event_id=${ event_id }&product_id=${ product_id }
    `);
};

export const postLab2Exception = (payload) => {
    return handledPostFetch(`${ API_BASE }/labs/labException`, payload);
};

export const getLab2Exception = (event_product_id) => {
    return handledGetFetch(`${ API_BASE }/labs/labException/${ event_product_id }`);
};

export const updateLab2Exception = (event_product_id, payload) => {
    return handledPostFetch(`${ API_BASE }/labs/labException/${ event_product_id }`, payload);
};

export {
    getAccountDetails, //Shared between admin an Cons
    handledGetFetch, //Needed for lab-display submodule
};
