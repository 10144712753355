import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import TableSearch from '../../../components/table-search';

import Well from '../../../components/well';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import FilteredTable from '../../../components/filtered-table';

class FilteredTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filteredId: props.reducer.filteredId,
        };

        this.loadCourses = R.bind(this.loadCourses, this);
        this.handleIdChange = R.bind(this.handleIdChange, this);
    }

    handleIdChange({ target }) {
        const numRegex = new RegExp('^[0-9]*$');

        if (numRegex.test(target.value)) {
            this.setState({ filteredId: target.value });
        }
    }

    loadCourses() {
        const { setId, fetchProducts, findUserLabs } = this.props;
        const id = this.state.filteredId;

        setId(id);
        fetchProducts(id);
        if (findUserLabs) {
            findUserLabs({ id });
        }
    }

    render() {
        const {
            name,
            appReducer,
            reducer: {
                results,
                filteredId,
            },
            productLabs,
            fetchLabs,
        } = this.props;

        const dateFieldMap = {
            Student: 'event',
            Event: 'session',
        };

        return (
            <div className={ `${name}-tab` }>
                <Well>
                    { R.map(({ title, id }) => (
                        <p key={ id }>
                            <Button
                                variant="link"
                                onClick={ () => this.setState({ filteredId: String(id) }) }>
                                { title } - { id }
                            </Button>
                        </p>
                    ), R.filter(({ type }) => type === name, [{
                        type: 'Event',
                        title: 'Sample Event (SF2018)',
                        id: 51135,
                    }, {
                        type: 'Student',
                        title: 'Sample Student (cmccowan)',
                        id: 2709112,
                    }, {
                        type: 'Student',
                        title: 'Sample Student (Melanie ?)',
                        id: 10824042,
                    }]))}
                    <TableSearch
                        name={ name }
                        handleChange={ this.handleIdChange }
                        handleSubmit={ this.loadCourses }
                        value={ this.state.filteredId }
                    />
                    { results.length > 0 ? (
                        <div className="results">
                            { name === 'Student' ? (
                                <Card>
                                    <h3>{ results[0].email }</h3>
                                    <div>
                                        <span className='label-name'>{`${name} Id:`}</span>
                                        <span>{ results[0].account_id }</span>
                                    </div>
                                </Card>
                            ) : (
                                <Card>
                                    <h3>{ results[0].title }</h3>
                                    {/*
                                        //Event Id isn't currently being passed
                                    <div>
                                        <span className='label-name'>Event id:</span>
                                        <span>{ eventResults[0].}</span>
                                    </div>
                                    */}
                                    <div>
                                        <span className='label-name'>Event Start:</span>
                                        <span>{ results[0].event_start }</span>
                                    </div>
                                    <div>
                                        <span className='label-name'>Event End:</span>
                                        <span>{ results[0].event_end }</span>
                                    </div>
                                </Card>
                            )}
                            <FilteredTable
                                appReducer={ appReducer }
                                rows={ results }
                                productLabs={ productLabs }
                                findLabs={ fetchLabs }
                                dateField={ dateFieldMap[name] }
                                tableContext= { name }
                            />
                        </div>
                    ) : filteredId !== '' && (
                        <div className="loading">Loading...</div>
                    )}
                </Well>
            </div>
        );
    }
}

FilteredTab.propTypes = {
    appReducer: PropTypes.object,
    studentReducer: PropTypes.object,
    name: PropTypes.string,
    reducer: PropTypes.object,
    fetchLabs: PropTypes.func,
    setId: PropTypes.func,
    productLabs: PropTypes.object,
    findUserLabs: PropTypes.func,
    findFilteredStudentLabs: PropTypes.func,
    fetchProducts: PropTypes.func,
};

export default FilteredTab;
