import React from 'react';
import PropTypes from 'prop-types';
import InputWrapper from '../input-wrapper';
import FormControl from 'react-bootstrap/FormControl';
import * as R from 'ramda';
/**
 * Putting the onChange in the FormGroup instead of on FormControl throws react error
 * * You provided a `value` prop to a form field without an `onChange` handler.
 * * This will render a read-only field. If the field should be mutable use `defaultValue`.
 * * Otherwise, set either `onChange` or `readOnly`.
 */

/**
 * @param {object} props
 * @return {object}
 */
export const InputType = (props) => props.type === 'date' ? (
    <div>
        Use DatePicker for date types ({ props.value })
    </div>
) : (
    <InputWrapper { ...R.pick(['label'], props) } >
        <FormControl
            { ...props }
            as={ props.type }
            value={ props.value || '' }
        />
    </InputWrapper>
);

InputType.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.array,
};
