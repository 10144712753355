import React from 'react';
import PropTypes from 'prop-types';
import InputType from '../input-type';

export const TextInput = (props) => (
    <InputType
        type={ props.type }
        { ...props }
    />
);

TextInput.propTypes = {
    type: PropTypes.string,
};
