import React from 'react';
import PropTypes from 'prop-types';
import SingleSelect from '../../../../components/single-select';
import * as R from 'ramda';
import Button from 'react-bootstrap/Button';

class Products extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            product_id: null,
            saveButton: {},
        };

        if (R.isNil(props.adminReducer.products)) {
            props.fetchProductList();
        }

        this.handleChange = R.bind(this.handleChange, this);
        this.handleSubmit = R.bind(this.handleSubmit, this);
    }

    handleSubmit() {
        const record = R.find(R.propEq('id', this.state.product_id), this.props.adminReducer.products);

        if (record) {
            this.props.setProductAccess({
                id: record.id,
                has_remote_lab: record.has_remote_lab ^ 1,
            });
        }
    }

    handleChange({ target }) {
        this.setState({
            [target.id]: target.value,
        });
    }

    render() {
        return (
            <div>
                <SingleSelect
                    id="product_id"
                    label="Toggle `has_remote_lab` for product"
                    selected={ this.state.product_id }
                    options={
                        R.map((product) => ({
                            value: product.id,
                            label: product.name + ' (' + (product.has_remote_lab === 1 ? 'Active' : 'Inactive') + ')',
                        }), this.props.adminReducer.products || [])
                    }
                    onChange={ this.handleChange }
                />
                <Button variant="success" onClick={ this.handleSubmit }>
                    Toggle
                </Button>
            </div>
        );
    }
}

Products.propTypes = {
    adminReducer: PropTypes.object,
    setProductAccess: PropTypes.func,
    fetchProductList: PropTypes.func,
    fetchAccountAccess: PropTypes.func,
    setAccountAccess: PropTypes.func,
    createAccountAccess: PropTypes.func,
};

export default Products;
