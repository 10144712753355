import React from 'react';
import PropTypes from 'prop-types';

import LabView from './lab-view';
import LabEdit from './lab-edit';
import LabStart from './lab-start';
import LabInstructions from './lab-instructions';
import LabCerts from './lab-certs';

import * as R from 'ramda';
import { PATH } from '../../constants';

class Lab extends React.Component {
    constructor(props) {
        super(props);

        const {
            match: {
                params: {
                    id,
                    action,
                },
            },
            fetchLabDetails,
            clearLabDetails,
            fetchLabInstructions,
        } = this.props;

        if (id) {
            fetchLabDetails(id);
            if (action === 'instructions') {
                fetchLabInstructions(id);
            }
        } else {
            clearLabDetails();
        }
    }
    render() {
        let view;

        switch (this.props.match.params.action) {
            case PATH.lab_actions.VIEW:
                view = <LabView { ...(R.pick(['appReducer', 'labReducer', 'match'], this.props)) }/>;
                break;
            case PATH.lab_actions.INSTRUCTIONS:
                view = <LabInstructions { ...(R.pick(['labReducer'], this.props)) }/>;
                break;
            case PATH.lab_actions.LABSTART:
                view = <LabStart { ...(R.pick(['labReducer', 'eventReducer'], this.props)) }/>;
                break;
            case PATH.lab_actions.CERTS:
                view = <LabCerts { ...(R.pick(['labReducer', 'uploadLabKeys'], this.props)) }/>;
                break;

            default:
                view = <LabEdit { ...(R.pick([
                    'upsertLab',
                    'fetchProducts',
                    'labReducer',
                    'match',
                    'clearLabDetails',
                ], this.props)) }/>;
        }
        return (
            <div className="lab">{ view }</div>
        );
    }
}

Lab.propTypes = {
    match: PropTypes.object,
    fetchLabDetails: PropTypes.func,
    clearLabDetails: PropTypes.func,
    fetchLabInstructions: PropTypes.func,
    uploadLabKeys: PropTypes.func,
};

export default Lab;
