import ACTION_TYPES from './action-types';

import { getAccountDetails } from '../../../utils/api';

export const fetchAccountDetails = () =>
    (dispatch) => {
        getAccountDetails().then(({ data }) => {
            dispatch({
                type: ACTION_TYPES.SET_ACCOUNT_DETAILS,
                payload: data,
            });
        }, () => {
            //@TODO
        });
    };
