export default {
    GET_PRODUCT_LIST: 'GET_PRODUCT_LIST',
    SET_PRODUCT_ACCESS: 'SET_PRODUCT_ACCESS',
    GET_ACCOUNT_ACCESS: 'GET_ACCOUNT_ACCESS',
    POST_ACCOUNT_ACCESS: 'POST_ACCOUNT_ACCESS',
    PUT_ACCOUNT_ACCESS: 'PUT_ACCOUNT_ACCESS',
    GET_USER_CACHE: 'GET_USER_CACHE',

    SET_APP_ACCESS: 'SET_APP_ACCESS',
    UPDATE_USER_INFO: 'UPDATE_USER_INFO',
    ACTION_FAILED: 'ACTION_FAILED',

    SET_LAB_FILTER: 'SET_LAB_FILTER',
    SET_LAB_PAGINATION: 'SET_LAB_PAGINATION',
    SET_EVENT_FILTER: 'SET_EVENT_FILTER',
    SET_STUDENT_FILTER: 'SET_STUDENT_FILTER',
    SET_PRODUCTS: 'SET_PRODUCTS',
    SET_LAB_INSTRUCTIONS: 'SET_LAB_INSTRUCTIONS',
    SET_EVENT_LABS: 'SET_EVENT_LABS',

    UPDATE_LAB_RESULTS: 'UPDATE_LAB_RESULTS',
    UPDATE_EVENT_RESULTS: 'UPDATE_EVENT_RESULTS',
    UPDATE_STUDENT_RESULTS: 'UPDATE_STUDENT_RESULTS',

    SET_LAB_DETAILS: 'SET_LAB_DETAILS',
    CLEAR_LAB_DETAILS: 'CLEAR_LAB_DETAILS',
    SET_LAB_DETAILS_FAILED: 'SET_LAB_DETAILS_FAILED',

    SHOW_NOTIFICATION_MESSAGE: 'SHOW_NOTIFICATION_MESSAGE',
    CLEAR_NOTIFICATION_MESSAGES: 'CLEAR_NOTIFICATION_MESSAGES',
    HIDE_NOTIFICATION_MESSAGE: 'HIDE_NOTIFICATION_MESSAGE',

    CERTS_UPLOADED: 'CERTS_UPLOADED',

    SET_STUDENT_LABS: 'SET_STUDENT_LABS',
    SET_FILTERED_STUDENT_LABS: 'SET_FILTERED_STUDENT_LABS',

};
