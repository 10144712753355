import React from 'react';
import PropTypes from 'prop-types';
import InputWrapper from '../input-wrapper';
import Button from 'react-bootstrap/Button';

/**
 * @param {object} props
 * @return {object}
 */
export const ButtonInput = (props) => (
    <InputWrapper id={ props.id } label={ props.label } >
        <div>
            <Button
                id={ props.id }
                value={ props.value }
                onClick={ props.onClick }>
                { props.text }
            </Button>
        </div>
    </InputWrapper>
);

ButtonInput.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func,
};
