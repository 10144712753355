import ACTION_TYPES from './action-types';
import { getEventProducts } from '../utils/api';

export const fetchEventLabs = (payload) => {
    return (dispatch) => getEventProducts(payload).then(({ data }) => {
        dispatch({
            type: ACTION_TYPES.CLEAR_NOTIFICATION_MESSAGES,
        });

        dispatch({
            type: ACTION_TYPES.UPDATE_EVENT_RESULTS,
            payload: data,
        });
    }, () => {
        //TODO: should probably create an error messages constant file
        dispatch({
            type: ACTION_TYPES.SHOW_NOTIFICATION_MESSAGE,
            payload: { type: 'danger', message: 'Invalid event id' },
        });

        dispatch({
            type: ACTION_TYPES.SET_EVENT_FILTER,
            payload: '',
        });
    });
};

export const setEventId = (id) => (dispatch) => {
    dispatch({
        type: ACTION_TYPES.UPDATE_EVENT_RESULTS,
        payload: {},
    });
    dispatch({
        type: ACTION_TYPES.SET_EVENT_FILTER,
        payload: id,
    });
};
